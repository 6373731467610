<template>
  <div class="container">
    <div class="content-box">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-menu
            :default-active="routePath"
            class="el-menu-vertical-demo"
            background-color="#d7e7f3"
            text-color="#343434"
            active-text-color="#0359AC"
            router
          >
            <el-menu-item index="/manager/studentManage">
              <i class="iconfont icon-xueshengguanli"></i>
              <span slot="title"> 学生管理</span>
            </el-menu-item>
			<el-menu-item index="/manager/psyHealthTeachPlan" v-if="roleKey == 'xin_li_plan' || isHeadTeacher">
			  <i class="iconfont icon-xueshengguanli"></i>
			  <span slot="title"> 心理健康教育教学计划</span>
			</el-menu-item>
            <el-menu-item index="/manager/creditManage">
              <i class="iconfont icon-xinyongguanli"></i>
              <span slot="title"> 学生信用管理</span>
            </el-menu-item>
            <el-menu-item index="/manager/archivesManage">
              <i class="iconfont icon-zongsudangan"></i>
              <span slot="title"> 学生综素档案</span>
            </el-menu-item>
			<el-menu-item index="/manager/zsSubAdmin" v-if="isHeadTeacher">
			  <i class="iconfont icon-zongsudangan"></i>
			  <span slot="title">综素班委会</span>
			</el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="19">
          <router-view />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		roleKey: this.$store.getters.roles[0].roleKey,
      routePath: "",
	  // 是否班主任
	  isHeadTeacher: this.$store.getters.userInfo.headTeacher == '是'
    };
  },
  created() {
    this.getPath();
  },
  watch: {
    $route: "getPath",
  },
  methods: {
    getPath() {
        this.routePath = this.$route.path;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .content-box {
    background: rgba(206, 223, 236, 0.45);
    padding: 18px 18px 18px 0;
    border-radius: 0 0 6px 6px;
    .el-menu {
      border: none;
      .el-menu-item {
        font-weight: bold;
        border-left: 4px solid transparent;
        i {
          font-size: 22px;
          font-weight: normal;
          color: #343434;
        }
        &.is-active {
          border-left: 4px solid #198af7 !important;
          background-color: #c3dcef !important;
          i {
            color: #0359ac;
          }
        }
      }
    }
  }
  .no-more {
    height: 50px;
    text-align: center;
    color: rgba(25, 138, 247);
    line-height: 50px;
    background: #cedfec;
    opacity: 0.46;
    border-radius: 6px;
    margin-top: 14px;
  }
}
</style>